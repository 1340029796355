<template>
  <div id="view-supplier-product">
    <v-container>
      <h1 class="mt-4">Supplier Product Details</h1>
      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        allowDismiss
        absolute
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
      <ProductsForm
        :initial-product="product"
        @save="updateProduct"
        :is-view="true"
        v-if="product.productId"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        type="success"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import ProductsForm from '@/views/Products/form'
import { DEFAULT_PRODUCT } from '@/constants/initial'
import dayjs from 'dayjs'

export default {
  name: 'UpdateSupplierProducts',
  title: 'Update Supplier Products - SAIS Operation UI Portal',
  components: {
    Alert,
    ProductsForm,
  },
  data() {
    return {
      product: { ...DEFAULT_PRODUCT },
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        type: 'success',
      },
    }
  },

  async created() {
    await this.$store.dispatch('moduleProducts/fetchProduct', {
      productId: this.$route.params.productId,
      supplierId: this.$route.params.supplierId,
    })
    this.product = this.$store.state.moduleProducts.selectedProduct
    this.product.dateCreated = dayjs(this.product.dateCreated).format('DD MMM YYYY HH:mm:ss')
    this.product.dateModified = dayjs(this.product.dateModified).format('DD MMM YYYY HH:mm:ss')
  },

  computed: {
    errorMessage() {
      return this.$store.state.moduleProducts.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleProducts.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },

  methods: {
    async updateProduct(item) {
      this.$store.dispatch('moduleProducts/putProduct', item).then((response) => {
        this.saveClicked = {
          isClicked: true,
          type: response.isError ? 'error' : 'success',
          isSuccess: !response.isError,
          message: response.isError
            ? response.errorMessage
            : 'The changes have been updated successfully',
        }

        if (!response.isError) {
          this.$router.push('/serviceregistry/products?created=true')
        }
      })
    },
  },
}
</script>
