<template>
  <v-form ref="productForm">
    <v-card class="pa-6 my-8">
      <AdsSelect
        v-model="product.supplierId"
        :items="supplierItems"
        class="mb-0"
        outlined
        label="Supplier"
        placeholder="Please select..."
        :disabled="isView"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
      <AdsSelect
        v-model="product.type"
        :items="typeItems"
        class="mb-0"
        outlined
        label="Type"
        placeholder="Please select..."
        :readonly="isReadOnlyUser"
        :rules="[validators.required]"
      />
      <AdsTextField
        v-model="product.productCode"
        label="Product Code"
        maxlength="200"
        :disabled="isView"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
        persistent-hint
      />
      <AdsTextField
        v-model="product.productId"
        label="Product Id"
        :disabled="true"
        v-if="product.productId"
      />
      <AdsTextField
        v-model="product.name"
        label="Product Name"
        maxlength="200"
        :rules="[validators.required, validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsSelect
        v-model="product.state"
        :items="stateItems"
        class="mb-0"
        outlined
        label="State"
        placeholder="Please select..."
        :readonly="isReadOnlyUser"
        :rules="[validators.required]"
      />
      <v-autocomplete
        v-model="product.services"
        :items="serviceItems"
        :multiple="true"
        chips
        deletable-chips
        outlined
        label="Subcategories"
        :rules="[validators.required]"
        :disabled="isReadOnlyUser"
        @change="updateServices"
      />
      <AdsSelect
        v-model="product.isFinancial"
        :items="financialItems"
        class="mb-0"
        outlined
        label="Finance Exclusive"
        placeholder="Please select..."
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
        disabled
      />
    </v-card>
    <v-card class="mb-4">
      <AdsDataTable
        :headers="headers"
        :items="items"
        disable-pagination="true"
        density="compact"
        search-label="Find products"
        sort-desc
      >
        <template v-slot:top />
        <template v-slot:footer />
        <template v-slot:bottom />
        <template v-slot:item.apiResources="{ item }">
          <div
            v-for="apiResource in item.apiResources"
            :key="apiResource"
          >
            {{ apiResource }}
          </div>
        </template>
      </AdsDataTable>
    </v-card>
    <v-card
      class="pa-6 my-8"
      v-if="isView"
    >
      <AdsTextField
        v-model="product.dateCreated"
        label="Date Created"
        :disabled="true"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="product.dateModified"
        label="Date Modified"
        :disabled="true"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <v-card>
      <AdsButton
        :primary="true"
        buttonText="Save"
        class="mr-4"
        @click="save"
        :disabled="isReadOnlyUser"
      />
      <AdsButton
        :primary="true"
        buttonText="Cancel"
        @click="cancel"
      />
    </v-card>
  </v-form>
</template>

<script>
import { AdsTextField, AdsSelect, AdsButton, AdsDataTable } from '@nswdoe/doe-ui-core'
import { DEFAULT_PRODUCT } from '@/constants/initial'
import { cloneDeep } from 'lodash'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'

export default {
  name: 'AddProducts',
  components: {
    AdsTextField,
    AdsSelect,
    AdsButton,
    AdsDataTable,
  },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    initialProduct: {
      type: Object,
      default: cloneDeep(DEFAULT_PRODUCT),
    },
  },
  data() {
    let selectedItems = []
    if (this.initialProduct.allowedResources) {
      selectedItems = this.initialProduct.allowedResources.map((item) => ({
        sifObjects: item.accountId,
        profile: item.fieldAccessTemplateId,
        apiName: item.apiName,
        apiResources: item.allowedActions,
      }))
    }

    return {
      product: this.initialProduct,
      errors: [],
      headers: [
        { text: 'SAIS SIF Objects', value: 'sifObjects' },
        { text: 'Profile', value: 'profile' },
        { text: 'API Resources', value: 'apiResources' },
      ],
      items: selectedItems,
      showErrors: false,
      stateItems: ['OPEN_FOR_BUSINESS', 'REGISTERED', 'DEREGISTERED', 'END_OF_LIFE'],
      typeItems: ['AMPS', 'OLT', 'OTHER'],
      financialItems: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      supplierItems: [],
      serviceItems: [],
      validators: {
        required: validators.required,
        alphaNumeric: validators.alphaNumeric,
        lowerCase: validators.lowerCase,
        alphaNumericHyphen: validators.alphaNumericHyphen,
        minMaxString: validators.minMaxString,
      },
    }
  },
  created() {
    if (this.isView) {
      this.supplierItems = [
        {
          text: this.product.supplierName,
          value: this.product.supplierId,
        },
      ]
      this.fetchServices()
    } else {
      this.$store.dispatch('moduleSuppliers/fetchSuppliers').finally(() => {
        this.supplierItems = this.$store.state.moduleSuppliers.suppliers
          .filter((s) => s.status !== 'Deprecated')
          .map((item) => ({
            value: item.supplierId,
            text: item.name,
          }))
      })
    }

    if (!this.product.isFinancial) {
      this.product.isFinancial = false
    }
  },
  // watch supplierId and services to update the services list
  watch: {
    'product.supplierId': function () {
      //  update product supplier name
      const supplier = this.$store.state.moduleSuppliers.suppliers.find(
        (supplier) => supplier.supplierId === this.product.supplierId
      )
      this.product.supplierName = supplier.name
      this.product.services = []

      this.items = []
      this.fetchServices()
    },
    'product.services': function () {
      this.updateServices()
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
  },
  methods: {
    fetchServices() {
      this.$store
        .dispatch('moduleServices/fetchServices', {
          supplierId: this.product.supplierId,
        })
        .finally(() => {
          this.serviceItems = this.$store.state.moduleServices.services.map((item) => item.id)

          if (this.isView) {
            let servicesDescription = this.product.services.map((service) => service.id)
            this.product.services = servicesDescription
          }
        })
    },
    updateServices() {
      this.items = []
      if (!this.product.services) return

      let objectItems = new Map()
      this.product.services.forEach((service) => {
        let selectedService = this.$store.state.moduleServices.services.find(
          (s) => s.id === service
        )
        if (
          selectedService &&
          selectedService.allowedResources &&
          selectedService.allowedResources.length > 0
        ) {
          selectedService.allowedResources.forEach((resource) => {
            if (objectItems.get(resource.accountId)) {
              objectItems.get(resource.accountId)['profile'].push(resource.fieldAccessTemplateId)
              resource.allowedActions.forEach((action) => {
                objectItems.get(resource.accountId)['apiResources'].add(action)
              })
            } else {
              let profile = [resource.fieldAccessTemplateId]
              let apiResources = new Set()
              resource.allowedActions.forEach((action) => {
                apiResources.add(action)
              })
              objectItems.set(resource.accountId, {
                profile: profile,
                apiName: resource.apiName,
                apiResources: apiResources,
              })
            }
          })
        }
      })
      objectItems.forEach((value, key) => {
        this.items.push({
          sifObjects: key,
          apiName: value.apiName,
          profile: value.profile.sort().reverse()[0],
          apiResources: Array.from(value.apiResources),
        })
      })
      //  if subcategory id has *finance-functional* then set isFinancial to true, case insensitive
      const isFinancial = this.product.services?.some((s) =>
        s?.toLowerCase()?.includes('finance-functional')
      )

      this.product.isFinancial = isFinancial ? true : false
    },
    cancel() {
      this.product = undefined
      this.$router.push('/serviceregistry/products')
    },
    save() {
      this.validate()
      this.errors = getFormErrors(this.$refs.productForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        //form to api request model mapping
        let productRequest = {
          supplierName: this.product.supplierName,
          productId: this.product.productId,
          productCode: this.product.productCode,
          type: this.product.type,
          name: this.product.name,
          state: this.product.state,
          isFinancial: this.product.isFinancial,
          services: [],
          allowedResources: [],
        }
        productRequest.supplierId = this.product.supplierId

        this.product.services.forEach((service) => {
          const selectedService = this.$store.state.moduleServices.services.find(
            (moduleService) => moduleService.id === service
          )
          productRequest.services.push({
            id: selectedService.id,
            name: selectedService.description,
          })
        })
        this.items.forEach((resource) => {
          productRequest.allowedResources.push({
            accountId: resource.sifObjects,
            apiName: resource.apiName,
            allowedActions: resource.apiResources,
            fieldAccessTemplateId: resource.profile,
          })
        })

        this.$emit('save', productRequest)
      }
    },
    validate() {
      if (this.$refs.productForm) {
        this.$refs.productForm.validate()
      }
    },
  },
}
</script>
